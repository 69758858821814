<template>
  <div class="module-servicePage">
    <div class="service">
      <img
        class="service-img"
        src="~image/components/servicePage/service-img1.png"
      />
      <img
        class="service-img"
        src="~image/components/servicePage/service-img2.png"
      />
      <img
        class="service-img"
        src="~image/components/servicePage/service-img3.png"
      />
      <div class="service-pay" @click="handlePayClick"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    async handlePayClick() {
      try {
        let res = await this.$http('orderService/buyVip', {});
        await this.$wx
          .pay(res)
          .then(() => {
            this.$toast.success('购买成功');
            this.$emit('paySuccess');
          })
          .catch(() => this.handlePayFail());
      } catch (error) {
        this.handlePayFail(JSON.parse(error.message));
      }
    },

    handlePayFail(err) {
      let config = {
        title: (err && '购买失败') || '',
        message: (err && err.message) || '购买失败'
      };
      this.$dialog.alert(config);
    }
  }
};
</script>

<style lang="scss" scoped>
.module-servicePage {
  width: 100%;
}

.service {
  width: 100%;
  height: 100vh;

  &-img {
    width: 100%;
  }

  &-pay {
    @include flex-center-center;
    position: fixed;
    left: 50%;
    bottom: 135px;
    width: 598px;
    height: 168px;
    border-radius: 16px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    background: url(~image/components/servicePage/pay-bg.png) 0 0 / cover
      no-repeat;
    transform: translateX(-50%);
  }
}
</style>
