<template>
  <van-popup
    class="module-payPopup"
    :value="show"
    round
    safe-area-inset-bottom
    position="bottom"
    @click-overlay="$emit('update:show', false)"
    @closed="handleClosed"
  >
    <div class="pay-content">
      <app-icon class="pay-icon" name="payPopup-icon" size="88"></app-icon>
      <p class="pay-label">{{ content }}</p>
      <p class="pay-price">
        <span class="pay-price_max">{{ price }}</span>
        <span>元</span>
      </p>
    </div>
    <van-radio-group
      class="pay-radios"
      v-model="payType"
      direction="horizontal"
    >
      <div class="radio-item" @click="payType = 2">
        <van-radio :name="2" checked-color="#FFCB64" icon-size="4.8vw">
          <p>
            <span>余额支付</span>
            <span class="money">可用余额({{ userMoney || '0.00' }})</span>
          </p>
        </van-radio>
      </div>
      <div class="radio-item" @click="payType = 1">
        <van-radio :name="1" checked-color="#FFCB64" icon-size="4.8vw"
          >微信支付
        </van-radio>
      </div>
    </van-radio-group>
    <div class="pay-btn_confirm" @click="handlePayBtnClick">支付</div>
    <div class="pay-btn_cancel" @click="$emit('update:show', false)">取消</div>
    <slot></slot>
  </van-popup>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    price: {
      type: [String, Number],
      default: '0'
    }
  },
  data() {
    return {
      // 1：微信支付，2：余额支付
      payType: 1
    };
  },
  computed: {
    ...mapState({ userMoney: state => state.user.userInfo.money })
  },
  methods: {
    ...mapMutations({ updateUserMoney: 'user/updateUserMoney' }),

    handlePayBtnClick() {
      this.$emit('confirm', this.payType);
    },

    handleClosed() {
      this.payType = this.userMoney ? 2 : 1;
    }
  },
  created() {
    this.payType = this.userMoney ? 2 : 1;
  }
};
</script>

<style lang="scss" scoped>
.module-payPopup {
  padding: 0 0 40px;
  overflow-y: initial;
}

.pay {
  &-content {
    @include flex-center-center;
    z-index: 1;
    padding: 30px 0 0;
    flex-direction: column;
    margin-bottom: 23px;
    border-bottom: 2px dashed #e9e9e9;
    border-radius: 32px 32px 0 0;
    background: #fff;
  }

  &-icon {
    margin-bottom: 40px;
    width: 88px;
    height: 88px;
  }

  &-label {
    margin-bottom: 5px;
    font-size: 26px;
    color: #969696;
  }

  &-price {
    height: 110px;
    font-size: 32px;
    color: #282828;

    &_max {
      font-size: 78px;
    }
  }

  &-radios {
    @include flex-between-center;
    margin-bottom: 43px;
    padding: 0 30px 0 23px;

    ::v-deep .van-radio {
      margin-right: 10px;
      overflow: initial;
    }

    .radio {
      &-item {
        @include align-center;
        height: 40px;
      }

      &-icon {
        @include flex-center-center;
        @include hairlines(all, #e3e3e3, 50%);
        border-radius: 50%;
        width: 44px;
        height: 44px;

        &_active {
          @extend .radio-icon;
          @include hairlines(all, #ffc077, 50%);
          background: #ffc077;
        }
      }
    }

    .money {
      margin-left: 20px;
      font-size: 26px;
      color: #999;
    }
  }
  &-btn {
    @include flex-center-center;
    margin: 30px auto 0;
    width: 630px;
    height: 98px;
    border-radius: 24px;
    font-size: 34px;

    &_cancel {
      @extend .pay-btn;
      border: 2px solid #ffbaaa;
      color: #777;
    }

    &_confirm {
      @extend .pay-btn;
      color: #fff;
      background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
      box-shadow: 0px 8px 0px 0px #ffc6b5;
    }
  }
}
</style>
