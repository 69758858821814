<template>
  <router-link
    to="/user/serviceList"
    class="module-serviceBanner"
    v-show="show"
  >
    <img class="img" src="~image/user/service/service-banner.png" />
    <img
      class="close"
      src="~image/user/service/service-bannerCloseBtn.png"
      @click.stop.prevent="hideBanner"
    />
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    hideBanner() {
      this.show = false;
    },

    showBanner() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-serviceBanner {
  @include align-center;
  justify-content: flex-end;
  padding: 0 30px;
  width: 100vw;
  height: 108px;
  background: linear-gradient(270deg, #ff3753 0%, #ffd06e 100%);

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close {
    z-index: 2;
    position: absolute;
    top: 32px;
    right: 23px;
    width: 40px;
    height: 40px;
  }
}
</style>
