<template>
  <div v-if="initialized">
    <div
      :class="`lottery-header${
        resultData.status > -1 ? `_resultStatus${resultData.status}` : ''
      }`"
    >
      <slot name="header" :resultData="resultData">
        <p :class="`header-issue`">第{{ resultData.issue }}期</p>
        <p class="header-time">
          <template v-if="resultTimeTipShow"> 今晚20:30开奖 </template>
          <template v-else>{{
            resultData.time | dateFormat('MM月DD日 ddd')
          }}</template>
        </p>
      </slot>
    </div>
    <template v-if="$slots.master">
      <div class="lottery-master"><slot name="master"></slot></div>
      <div class="splitLine"></div>
    </template>
    <template v-if="resultData.status > -1 && forecastData.list.length">
      <div class="lottery-result">
        <div class="result-title">
          <p>开奖号码</p>
          <p class="result-total">
            本期有<span class="result-total_blue">{{ resultData.total }}</span
            >人预测命中
          </p>
        </div>
        <div class="result-values">
          <p
            class="result-value"
            v-for="(val, idx) in resultData.value"
            :key="idx"
          >
            {{ val }}
          </p>
        </div>
      </div>
      <div class="splitLine"></div>
    </template>
    <div class="lottery-forecast">
      <slot name="forecast"></slot>
      <div class="forecast-container">
        <p class="forecast-title" v-if="forecastData.list.length">
          {{ forecastData.type | getForecastText }}
        </p>
        <div class="forecast-data">
          <template v-if="!forecastData.list.length">
            <p class="forecast-empty">本期未发布预测</p>
          </template>
          <template v-else-if="forecastData.type <= 10">
            <div
              class="forecast-item"
              v-for="(item, itemIdx) in forecastData.list"
              :key="item.itemIdx"
            >
              <p class="forecast-tag">{{ item.tag }}</p>
              <p
                :class="`forecast-value${
                  val === resultData.value[itemIdx] ? '_active' : ''
                }`"
                v-for="(val, valIdx) in item.value"
                :key="valIdx"
              >
                {{ val }}
              </p>
            </div>
          </template>
          <div
            class="forecast-item"
            v-else-if="forecastData.type < 20 || forecastData.type > 30"
          >
            <p
              v-for="(val, valIdx) in forecastData.list[0]"
              :key="valIdx"
              :class="
                val
                  | getForecastNumberJudge(resultData.value, forecastData.type)
                  | getForecastValueClass
              "
            >
              {{ val }}
            </p>
          </div>
          <div
            v-else-if="forecastData.type < 30"
            :class="
              forecastData.value
                | getForecastNumberJudge(resultData.value, forecastData.type)
                | getForecastItemClass
            "
          >
            <p
              v-for="(val, valIdx) in forecastData.list[0]"
              :key="valIdx"
              class="forecast-value"
            >
              {{ val }}
            </p>
          </div>
        </div>
        <img
          v-if="
            forecastData.list.length &&
            resultData.status !== -1 &&
            resultData.statusImg
          "
          :src="resultData.statusImg"
          class="forecast-img"
        />
      </div>
    </div>
    <!-- <div class="splitLine" v-if="prizeData.type !== 0"></div>
    <p class="lottery-prize_integral" v-if="prizeData.type === 1">
      <span>本期夺得奖金：</span>
      <span class="prize-value">¥ {{ prizeData.value }}</span>
    </p>
    <p class="lottery-prize_money" v-if="prizeData.type === 2">
      <span>积分返现：</span>
      <span class="prize-value">{{ prizeData.value }}积分</span>
    </p> -->
  </div>
</template>

<script>
import resultStatus1Img from 'image/lottery/result_status1.png';
import resultStatus2Img from 'image/lottery/result_status2.png';

export default {
  props: {
    lotteryData: {
      type: Object,
      default() {
        return {
          id: 0,
          no: 0,
          checkNum: 0,
          date: 0,
          forecastList: [],
          forecastStatus: 0,
          forecastType: 10,
          rightNumber: '',
          failIntegral: 0
        };
      }
    }
  },
  data() {
    return {
      resultData: null,
      forecastData: null,
      prizeData: null
    };
  },
  watch: {
    lotteryData: {
      immediate: true,
      handler(data) {
        if (!data) return;
        this.initialization(data);
      }
    }
  },
  computed: {
    initialized() {
      return this.resultData && this.forecastData && this.prizeData;
    },
    resultTimeTipShow() {
      let { resultData: data } = this;
      if (!data || data.status !== -1 || !data.time) return false;
      return this.$dayjs().isSame(data.time, 'day');
    }
  },
  filters: {
    getForecastItemClass(isActive) {
      return isActive ? 'forecast-item_active' : 'forecast-item';
    },
    getForecastValueClass(isActive) {
      return isActive ? 'forecast-value_active' : 'forecast-value';
    }
  },
  methods: {
    initialization(data) {
      let resultData = {
        // -1: 未开奖; 0: 未中奖; 1: 全中; 2: 中四/直码外的预测命中
        status: -1,
        issue: data.no || 0,
        time: this.$dayjs(data.date).valueOf(),
        value: data.rightNumber || '',
        total: data.checkNum || 0
      };
      let forecastData = {
        type: data.forecastType || 10,
        list:
          data.forecastType <= 10 && data.forecastList.length
            ? [
                { tag: '万位', value: data.forecastList[0] },
                { tag: '千位', value: data.forecastList[1] },
                { tag: '百位', value: data.forecastList[2] },
                { tag: '十位', value: data.forecastList[3] },
                { tag: '个位', value: data.forecastList[4] }
              ]
            : data.forecastList
      };
      let prizeData = {
        // 0: 无奖励; 1: 奖金; 2: 积分
        type: 0,
        value: 0
      };
      switch (data.forecastStatus) {
        case 1:
          if (
            data.forecastType <= 10 &&
            data.forecastList[4].indexOf(resultData.value[4]) !== -1
          ) {
            resultData.status = 1;
            resultData.statusImg = resultStatus1Img;
            prizeData = {
              type: 1,
              value: 100000
            };
          } else {
            resultData.status = 2;
            resultData.statusImg = resultStatus2Img;
          }
          break;
        case 2:
          resultData.status = 0;
          resultData.statusImg = '';
          if (data.failIntegral) {
            prizeData = {
              type: 2,
              value: data.failIntegral
            };
          }
          break;
      }
      this.resultData = resultData;
      this.forecastData = forecastData;
      this.prizeData = prizeData;
    }
  },
  mounted() {
    // this.initialization();
  }
};
</script>

<style lang="scss" scoped>
$lotteryItem-paddingX: 34px;

@mixin wrap {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 10px 24px 0px rgba(141, 141, 141, 0.16);
}

@mixin coverShadow {
  z-index: 2;
  position: relative;
  background: #fff;
}

.lottery {
  &-master {
    @include wrap;
  }

  &-header {
    @include flex-between-center;
    @include wrap;
    padding: 0 $lotteryItem-paddingX;
    height: 100px;
    border-radius: 16px 16px 0 0;
    font-size: 32px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(
      135deg,
      #ffd8af 0%,
      #ff6767 10%,
      #8356ff 69%,
      #80d2f1 100%
    );

    & + .lottery-forecast > .forecast-container {
      border-radius: 0 0 16px 16px;
    }

    &_resultStatus0 {
      @extend .lottery-header;
      background: linear-gradient(180deg, #e1e1e1 0%, #b1b1b1 100%);
    }

    &_resultStatus1 {
      @extend .lottery-header;
      background: linear-gradient(180deg, #ff7b5a 0%, #ff182f 100%);
    }

    &_resultStatus2 {
      @extend .lottery-header;
      background: linear-gradient(180deg, #a1bdff 0%, #3d75fe 100%);
    }

    .header {
      &-issue {
        color: #fff;
      }

      &-result {
        &_text {
          margin-left: 45px;
          color: #fe9139;
        }
      }
    }
  }

  &-result {
    @include wrap;
    border-radius: 0 0 16px 16px;

    .result {
      &-title {
        @include justify-between;
        padding: 30px $lotteryItem-paddingX 26px;
        width: 100%;
        border-radius: 0 0 16px 16px;
        font-size: 28px;
        color: #4d4d4d;
      }

      &-total {
        color: #999;

        &_blue {
          color: #4a7cf6;
        }
      }

      &-values {
        @include coverShadow;
        display: flex;
        justify-content: space-around;
        padding: 0 $lotteryItem-paddingX 40px;
        width: 100%;
        border-radius: 0 0 16px 16px;
      }

      &-value {
        @include flex-center-center;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        font-size: 42px;
        color: #fff;
        background: #ff3613;
      }
    }
  }

  &-forecast {
    @include wrap;
    font-size: 28px;

    .forecast {
      &-container {
        @include coverShadow;
        @include align-center;
        padding: 30px $lotteryItem-paddingX;
        border-radius: 16px;
      }

      &-data {
        flex: 1;
      }

      &-empty {
        padding: 18px 0;
        text-align: center;
        font-size: 36px;
        color: #ababab;
      }

      &-title {
        @include flex-center-center;
        align-self: flex-start;
        margin-right: 20px;
        padding: 6px 0;
        width: 128px;
        border-radius: 28px;
        font-size: 32px;
        color: #323232;
        background: #fff1dc;
      }

      &-item {
        @include align-center;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &_active {
          @extend .forecast-item;
          color: #ff9681;
        }
      }

      &-tag {
        margin-right: 24px;
        color: #999;

        &_block {
          @extend .forecast-tag;
          color: #333;
        }
      }

      &-value {
        @include flex-center-center;
        margin-right: 9px;
        padding: 0 10px;
        min-width: 36px;
        min-height: 36px;
        border-radius: 18px;

        &_active {
          @extend .forecast-value;
          color: #fff;
          background: #ff9681;
        }
      }

      &-img {
        position: absolute;
        top: 50%;
        right: 18px;
        width: 114px;
        height: 115px;
        transform: translateY(-50%);
      }
    }
  }

  &-prize {
    @include flex-center-center;
    @include wrap;
    position: relative;
    padding: 40px 36px;
    font-size: 36px;
    font-weight: 500;
    color: #4d4d4d;

    &_integral {
      @extend .lottery-prize;
      background: #fff url(~image/user/order/prize-bg_integral.png) center/618px
        70px no-repeat;
    }

    &_money {
      @extend .lottery-prize;
      background: #fff url(~image/user/order/prize-bg_money.png) center/618px
        70px no-repeat;
    }

    .prize-value {
      color: #ff2b06;
    }
  }
}

.splitLine {
  z-index: 3;
  margin: 0 auto;
  width: 646px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.08);
  background: #fff;
}
</style>
